<template>
	<div class="attach">
		<h3>Attach person to a comment</h3>
		<input type="text"  v-model="filter" placeholder="Search..."/>
		<div class="names">
			<div class="name" v-for="name in names" :key="name"
				:class="[(!name.includes(filter) ? 'name_hidden' : null), (listOfAttached.includes(name) ? 'name_choosen' : null)]"
				@click="addTolistOfAttached(name)"
			>
				{{ name.includes(filter) ? name : ''}}
			</div>
		</div>
		<div class="button_group">
			<div class="button secondary" @click="cancel()">cancel</div>
			<div class="button" @click="transition()">Confirm</div>
		</div>
	</div>
</template>

<script setup>
import { defineEmits, defineProps, ref, toRefs } from 'vue';
const props = defineProps({ listOfAttached: Array });
let listOfAttached = ref(props.listOfAttached);

let names = ref(
	['Leonid Oleksiienko', 'Victor Rohovyi', 'Jason Connor', 'Cody Connor', 'David McKay', 'Hannah McKay', 'Wesley Rawlins', 'Stacey Engel', 'Joshua Rodriguez']
);
let filter = ref('');

function addTolistOfAttached(name) {
	if (listOfAttached.value.includes(name)) {
		let i = listOfAttached.value.indexOf(name);
		console.log(listOfAttached.value.indexOf(name), 'listOfAttached.value.indexOf(name);')
		if (i !== -1) {
			listOfAttached.value.splice(i, 1);
		}
	} else {
		listOfAttached.value.push(name);
	}
	// console.log(listOfAttached.value,'listOfAttached.value')
};
const emit = defineEmits(['cancel','transition']);
function cancel() {
  emit('cancel');
};
function transition() {
  emit('nameTransition',listOfAttached.value);
}
</script>

<style lang="scss" scoped>
	.attach{
		position: fixed;
		padding: 32px 32px 32px 32px;
    left: 50%;
    top: 50%;
		width: 520px;
    max-width: 520px;
    max-height: calc(100% - 32px);
		min-height: 440px;
    transform: translate(-50%, -50%);
		background-color: #fff;
		z-index: 2;
		border-radius: 16px;
		box-shadow: 0px 6px 20px 8px rgba(0, 0, 0, 0.3);
		h3{
			margin: 0;
		}
		input{
			height: 40px;
			line-height: 38px;
			outline: none;
			box-sizing: border-box;
			border: 1px solid #bcbcbc;
			border-radius: 6px;
			display: inline-block;
			width: 100%;
			margin: 10px 0 10px;
			font-size: 1rem;
			text-indent: 5px;
		}
		.names{
			max-height: 270px;
			overflow-y: auto;
			display: flex;
			flex-wrap: wrap;
			.name_hidden{
				display: none;
			}
			.name{
				height: 32px;
				line-height: 32px;
				margin: 5px;
				cursor: pointer;
				// border: 1px solid #bcbcbc;
				box-sizing: border-box;
				width: fit-content;
				padding: 0 5px;
				border-radius: 3px;
			}
			.name_choosen{
				// border: 1px solid #006C3E;
				color: #006C3E;
				background-color: rgba(0, 108, 62, 0.15);
			}
		}
		.button_group{
			position: absolute;
			bottom: 32px;
			left: 32px;
			right: 32px;
			.button{
				width: calc(50% - 8px);
			}
			.button:first-child{
				margin-right: 16px;
			}
		}
	}
</style>