<template>
<!-- <LoadingOverlay :show="beforeLoad"/> -->
  <main class="main_v8 book" :style="{right: (this.windowWidth > 1024  && posts.length > 1 ? '375px' : null), paddingTop: (posts.length > 1 && this.windowWidth < 1025 ? '80px': null)}">
    <breadcrumb :style='{top: (posts.length > 1 && this.windowWidth < 1025 ? `42px` : null )}'></breadcrumb>
    
    <div class="book_area">
    <div class="edit">
      <p class="status" 
      v-if="category.categoryid !== null"
      :style="[category.categoryid !== null ? {backgroundColor: category.color} : {backgroundColor: '#bcbcbc'} ]">
        {{category.name}}
      </p>

      <h2 class="page_header" @click="here" :style="[edit ? { paddingLeft: '46px', paddingRight: '50px' } : null]">
        <div class="delete_this_page" @click="deleteCurrent = true" v-if="edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('delete'))"></div>
        {{this.title}}
        <div class="edit_header_button" v-if="edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('edit'))"  @click.stop="editBook = true"></div>
      </h2>
      <p class="page_subheader" v-if="this.subtitle.toLowerCase() != 'new book description'"
      :style="[edit ? { paddingLeft: '46px'} : null]">{{this.subtitle}}</p>

      <transition name="fade">
        <shade v-if="editBook || widescreen_vid || deletePageModal >= 0 || deleteBook || bookEdit || deleteCurrent || windowWidth < 1025 && contents" @click.prevent="universalSave"></shade>
      </transition>

      <transition name="totop_modal">
        <div v-if="editBook" class="updown book_info image_process" @click.stop :style="[[windowWidth < 600 ? { paddingBottom: '60px' } : {}], [imageWarn >= 0  || deleteCategory >= 0  || deleteCategoryCommit ? { zIndex: '1' } : null]]">
          <span class="options_toggle" @click="universalSave">{{ imgEdit ? 'IMAGE MENU' : 'BOOK EDIT' }}</span>

          <div class="tag">
            <p v-if="!imgEdit">Image</p>
            <transition v-if="imgEdit && editBookImg !== 'url()'">
              <div :style="{ height: '100%', width: '100%', backgroundSize: 'cover', borderRadius: '12px', position: 'relative', flex: '1', aspectRatio: '4/3' }">
                <transition v-if="imageEditor">
                  <imgx :src="editBookImg.slice(4).slice(0, -1)" :imageEditor="true"
                        @editedImageUploadCompleted="editedImageUploadComplete"
                        @editedImageClosed="editedImageClose"></imgx>
                </transition>
                <transition v-else>
                  <div v-if="editBookImg !== 'url()' || imgEdit"
                    :class="uploadingImage ? 'lighter' : null"
                    :style="{ backgroundImage: editBookImg, height: '100%', width: '100%', backgroundSize: 'cover', borderRadius: '12px', position: 'relative', flex: '1' }">
                    <span class="blocks_loader" v-if="uploadingImage"></span>
                    <div class="delete_img_button" @click="imageWarn = 0; imgEdit = false"></div>
                  </div>
                </transition>
              </div>
            </transition>
            <transition v-else>
              <div v-if="editBookImg !== 'url()'"
                   :style="{ backgroundImage: editBookImg, width: '100%', backgroundSize: 'cover', borderRadius: '12px', position: 'relative', flex: '1', aspectRatio: '4/3' }">
                <div class="edit_img_button" v-if="!imgEdit" @click="imgEdit = !imgEdit"></div>
                <!-- <div class="delete_img_button" v-if="!imgEdit" @click="editBookImg = 'url()'"></div> -->
                <!-- <div class="delete_img_button" @click="imageWarn = 0; imgEdit = false"></div> -->
              </div>
              <DropZoneImageDiv v-else-if="!imgEdit" @click.prevent="imgEdit = true;"/>
<!--              <DropZoneImage v-else :index='0' :height="100" @drop.prevent="drop" @change="selectedFile"-->
<!--                             @uploadCompleted="uploadComplete" @uploadStarted="uploadStarted"/>-->
            </transition>
          </div>

          <div v-if="imgEdit && !imageEditor">
            <div v-if="editBookImg == 'url()'" class="dropzone_alike" :style="{ color: (uploadingImage ? 'rgba(0, 108, 62, 0.0)' : '') }">
              No Image
              <span class="blocks_loader" v-if="uploadingImage"></span>
            </div>
            <div class="action_button upload_img">
              <DropZoneImage :index='0' :height="100" @drop.prevent="drop" @change="selectedFile"
                             @uploadCompleted="uploadComplete" @uploadStarted="uploadStarted"/>
            </div>
            <div class="action_button paste_img" @click="pasteImageFromClipboard">Paste from Clipboard</div>
            <div v-if="editBookImg != 'url()'" class="action_button edit_img" @click="tempEditBookImg = editBookImg; imageEditor = true;">Edit Image</div>
          </div>
          <div v-else-if="!imageEditor">
            <div class="tag">
              <p>Title</p>
              <input v-if="this.title.toLowerCase() == 'new book'" type="text" v-model="comparisonTitle" :placeholder="this.title">
              <input v-else type="text" v-model="this.title">
            </div>

            <div class="tag">
              <p>Description</p>
              <input v-if="this.subtitle.toLowerCase() == 'new book description'" type="text" v-model="comparisonSubTitle" :placeholder="this.subtitle">
              <input v-else type="text" v-model="this.subtitle">
            </div>

            <div class="tag category_menu" v-if="!addNewCat">
              <p>Category</p>
              <div class="current_category" @click="showAllList = !showAllList" 
              :style="{ backgroundColor: category.color !== '' && category.name !== undefined && category.name !== null ? category.color : '#bcbcbc' }">
                {{ category.name !== '' && category.name !== undefined && category.name !== null ? category.name : 'No Category'}}
              </div>
            </div>

            <transition name="category_list">
              <div class="cat_list_wrapper" v-if="showAllList">
                <ul v-if="showAllList" class="list_of_categories">
                  <li v-for="cat in categories" :key="cat"                   
                  :style="{ backgroundColor: cat.color }">{{ cat.name }}
                  <span v-if="cat.categoryid != null" @click="changeCategory(cat)" @mousedown.prevent="newEditBookCategory = cat; longpressCat()" @mouseup="longpressCatOff()" @touchstart="newEditBookCategory = cat; longpressCat()" @touchend="longpressCatOff()"></span></li>
                  <li @click="changeCategory(null)" :style="{ backgroundColor: '#bcbcbc' }">No Category</li>
                </ul>
                <p>*To edit or delete any of the unselected categories, press and hold.</p>
              </div>
            </transition>

            <div class="add_new_cat" v-if="addNewCat">
              <input placeholder="Operation" v-model="newCatName">

              <span class="choose_color" @click="ifChooseColor = !ifChooseColor">
                <span class="current_color" :style="{ backgroundColor: newCategory.color }"></span>
                <span class="rotating_arrow" :style="[ifChooseColor ? { transform: 'rotate(180deg)' } : '']"></span>
              </span>

              <transition name="colors">
                <div class="palette" v-if="ifChooseColor">
                  <span class="colors" v-for="color in colors" :key="color" @click="newCategory.color = color; ifChooseColor = false">
                    <span :style="{ backgroundColor: color }"></span>
                  </span>
                </div>
              </transition>
            </div>
          
            <div class="add_new_cat" v-if="editCategory">
              <input v-model="category.name">
              <span class="choose_color" @click="ifChooseColor = !ifChooseColor">
                <span class="current_color" :style="{ backgroundColor: category.color }"></span>
                <span class="rotating_arrow"
                  :style="[ifChooseColor ? { transform: 'rotate(180deg)' } : '']"></span>
              </span>
 
              <transition name="colors">
                <div class="palette" v-if="ifChooseColor">
                  <span class="colors" v-for="color in colors" :key="color"
                    @click="newCategory.color = color; ifChooseColor = false">
                    <span :style="{ backgroundColor: color }"></span>
                  </span>
                </div>
              </transition>
            </div>
            <div class="new_cat">
              <div v-if="addNewCat || editCategory" @click="addNewCat ? saveCategory(0) : saveCategory(1)" class="button">SAVE</div>
              <div v-if="addNewCat || editCategory" @click="addNewCat = false; editCategory = false" class="button secondary">CANCEL</div>
            </div>
            
            <div v-if="!addNewCat && !editCategory" class="category_actions">
              <div @click="showAllList = false; addNewCat = true;">add</div>
              
              <div v-if="category.categoryid == null" class="disabled">edit</div>
              <div v-else @click="showAllList = false; editCategory = true;">edit</div>

              <div v-if="category.categoryid == null" class="disabled">delete</div>
              <div v-else @click="deleteCategoryCommit = true; deleteCategory = category.categoryid">delete</div>
            </div>
          </div>
          <div v-if="!imgEdit" class="button_updown" @click="universalSave(editedToSave)"><span>Done</span></div>
          <div v-if="imgEdit && !imageEditor" class="button_updown" @click="imgEdit = false"><span>Done</span></div>
        </div>
      </transition>

      <div class="add_new_post_top" v-if="edit && posts.length != 0 && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('add'))" @click="addNewPost(0)" > Add Page </div>
      
      <!-- <div class="add_first_post" v-if="posts.length == 0 && beforeLoad && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('add'))" @click="edit ? addNewPost(0) : null"
        :style="[edit ? { 'border-color': '#099f45', color: '#099f45', cursor: 'pointer' } : { 'border-color': '#787878', color: '#787878' }]"> Add Page </div> -->

      <div v-if="edit && posts.length == 0 && beforeLoad" class="no_content functional" style="margin-top: 16px;">
        <div class="content_image"></div>
        <p class="functional">You don’t have any pages in this book</p>
        <p class="caption">Please click the Add Page button to get started.</p>
        <div class="button secondary caption" @click.stop="edit ? addNewPost(0) : null">Add Page</div>
      </div>
      <div v-if="!edit && posts.length == 0 && beforeLoad" class="no_content functional" style="margin-top: 16px;">
        You don’t have any pages<br/><br/>
        To get started, make sure you have editor privileges.  Once you do, click on the menu icon in the upper left hand corner:<br/>
        <i></i>
        Once you’ve clicked that icon, you will see a button just under your name that says “Edit Mode”. Click it and you’ll have the ability to add pages.
      </div>

      <draggable
        v-model="posts"
        ghost-class="ghost-card"
        handle=".dragger"
        @change="saveSortOrder"
        :animation="200"
      >
        <transition-group>
          <div
            v-for="(element, index) in posts"
            :key="element.id"
            :class="[element.class, { 'draggable': edit }, 'menu_item', , { 'collapsed': this.$store.state.bookCollapse || element.class === 'pdf_box' }]"
            :ref="'postIndex'+index"
            :data-scroll="index"
            :id="element.id"
          >
            {{element.content_type}}
            <div class="dragger {{element.content_type}}" id="dragger" v-if="edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || element.permissions?.includes('edit'))"></div>
            <button v-if="edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || element.permissions?.includes('delete'))" class="delete_post" @click.prevent="deletePageIndex(index)"></button>

            <router-link v-if="element.class === 'txt_box' && edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || element.permissions?.includes('edit'))" class="edit_page"
              @click.prevent="$store.commit('contentEditInfo', index); this.$store.commit('toggle'); this.$store.state.postUploadFiles = element.files; this.$store.state.scrollPoint = 'postIndex'+index; here('postIndex'+index)" :to="{name: this.route, params: { id: element.postid}}"
            ></router-link>

            <router-link v-if="element.class === 'video_box' && edit || element.class === 'pdf_box' && edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || element.permissions?.includes('edit'))" class="edit_page"
              @click.prevent="$store.commit('contentEditInfo', index); this.$store.commit('toggle');" :to="{name: this.route, params: { id: element.postid}}"
            ></router-link>
            <!-- <span class="widescreen" v-if="!edit && element.class === 'video_box'" @click="widescreen($event)"></span> -->

            <div :class="!edit ? 'collapse_wrapper_full' : 'collapse_wrapper_small'" style="" @click="collapsePost($event, element.id)">
              <div class="collapse"></div>
            </div>

            <span class="element_subtitle">Page {{index + 1}}</span>
            <h3 class="tileTitle" style="height: 1.29rem;">{{element.title}}</h3>
          
            <div v-if="element.class === 'video_box'" class="toggle_btn" :id="element.id">
<!--video player-->  
                
          <HlsVideoPlayer 
            :src="element.src"
            :muted="false"
            :controls="true"
            :autoplay="false"
            :skipDuration="10"
            @timeupdate="onTimeUpdate"
            @pause="onVideoPause"
            @play="onVideoPlay"
            @ended="onVideoEnded"
          />
            
              
            </div>

            <div v-if="element.class === 'txt_box'" class="toggle_btn txt_toggle" :id="element.id">
              <div v-highlightjs="sourcecode" id="text_to_style" @click="doCopy($event)" v-html="element.html">
              </div>
              <div class="documents">
                <div class="doc" v-for="(doc, index) in element.files" :key="index" :class="doc[1]" @click="openFile(doc[3])">
                  <a :href="doc[3]" target="_blank" @click.prevent>
                    <span v-text="doc[0]"></span>
                    <span v-text="formatBytes(doc[2])"></span>
                  </a>
                </div>
              </div>
            </div>

            <div v-if="element.class === 'pdf_box'" :id="element.id">
              <div class="toggle_btn">
                <PSPDFKitContainer v-if="element.src != '' && visiblePDF.includes(element.id)" :pdf-file="element.src" :key-index="element.id.toString()" @loaded="handleLoaded" />
              </div>
              <div v-if="element.class === 'pdf_box'" v-html="element.html"></div>

            </div>
            
            <div class="media" v-if="commentsActive">
              <span class="views" v-if="element.views >= 0">{{element.likes}}</span>
              <span class="likes" v-if="element.likes >= 0">{{element.likes}}</span>
              <span class="comments" v-if="element.comments >= 0">{{element.comments}}</span>
              <div class="comment_section" @click="commentsActive = !commentsActive"><span>Add Comment</span></div>
              <!-- <div class="comment_section"><span>Add Comment</span></div> -->
              <div class="share"><span>Share</span></div>
            </div>

            <div class="media" v-if="commentsActive" style="margin-top: 40px; position: relative">
              <div class="hide_comments" @click="commentsActive = !commentsActive"></div>
              <commentary :comments="comments"/>
            </div>

            <div class="add_new_post" v-if="edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || element.permissions?.includes('add'))" @click="addNewPost(index + 1)"> Add Page</div>
          </div>
        </transition-group>
      </draggable>

      <transition name="totop_modal">
        <div v-if="bookEdit" class="updown book_info" :style="[width < 600 ? { paddingBottom: '60px' } : {}]" @click.stop>
          <span class="options_toggle" @click="universalSave">BOOK EDIT</span>
          <div class="tag">
            <p>Title</p>
            <input type="text" v-model="this.title">
          </div>
          <div class="tag">
            <p>Subtitle</p>
            <input type="text" v-model="this.subtitle">
          </div>
          
          <div class="tag">
            <p>Category</p>
            <div class="current_category" @click="showAllList = !showAllList">
              {{ this.category.name }}
              <span></span>
              <ul v-if="showAllList" class="list_of_categories">
                <li v-for="(cat, index) in categories" :key="index" @click="showAllList = !showAllList">{{ cat.name }}</li>
              </ul>
            </div>
          </div>
          <div v-if="showAllList" class="button_updown" style="margin-top: 68px" @click="showAllList = false; addNewCat = true;">+ ADD NEW CATEGORY</div>
          <div v-if="addNewCat" class="button_updown new_cat" style="margin-top: 68px" @click="saveCategory" >SAVE NEW CATEGORY</div>
        </div>
      </transition>
    </div>

    <transition :name="!this.mobile ? 'contents' : 'contents_mobile'">
      <div v-if="this.windowWidth > 1024 && posts.length > 1 || this.contents && posts.length > 1" class="contents">
        <h3>Contents</h3>
        <div class="list">
          <p v-for="(instance, index) in posts" :key="instance.id" :class="index == this.$store.state.postToHighlight ? 'active' : null" @click="gogogo(instance.id)">{{ 'Page '+ `${index+1}`}}{{ instance.title != '' ? ': ' + instance.title : '' }}</p>
        </div>
        <div class="button" v-if="this.windowWidth < 1025" @click="contents = !contents">Done</div>
      </div>
    </transition>

    <div class="contents_path" v-if="this.windowWidth <= 1024 && posts.length > 1" @click="contents = !contents">
      <span>Contents</span>
      <div v-if="this.posts[this.$store.state.postToHighlight]">{{'Page '+`${(this.$store.state.postToHighlight)*1+1}`}}{{ this.posts[this.$store.state.postToHighlight].title == '' ? '' : ': ' + this.posts[this.$store.state.postToHighlight].title }} </div>
    </div>

  </div>


    <div class="delete_edit_cat" v-if="deleteCategory >= 0" :style="[deleteCategoryCommit ? {zIndex: '1'} : null]">
      <h3>Edit / Delete Category</h3>
      <div class="delete_top">
        Delete Category
        <div @click="catToDelete = newEditBookCategory.categoryid; deleteCategoryCommit = true">Delete</div>
      </div>
      <div>
        <p>Edit Category</p>
        <div class="add_new_cat">
          <input v-model="newEditBookCategory.name">
          <span class="choose_color" @click="ifChooseColor = !ifChooseColor">
            <span class="current_color" :style="{ backgroundColor: newEditBookCategory.color }"></span>
            <span class="rotating_arrow"
              :style="[ifChooseColor ? { transform: 'rotate(180deg)' } : '']"></span>
          </span>

          <transition name="colors">
            <div class="palette" v-if="ifChooseColor">
              <span class="colors" v-for="color in colors" :key="color"
                @click="newEditBookCategory.color = color; ifChooseColor = false">
                <span :style="{ backgroundColor: color }"></span>
              </span>
            </div>
          </transition>
        </div>
      </div>
      <div class="buttons">
        <btn class="secondary" v-text="'cancel'" @click="deleteCategory = -1; count = 0; interval = null;"></btn>
        <btn v-text="'save'" @click="saveCategory(4)"></btn>
      </div>
    </div>

    <div class="delete_anything" @click.stop v-if="deletePageModal >= 0 || deleteCurrent || imageWarn >= 0 || deleteCategoryCommit">
      <h3 v-if="deleteCurrent">Book Deletion Warning!</h3>
      <h3 v-if="deletePageModal >= 0">Page Deletion Warning!</h3>
      <h3 v-if="imageWarn >= 0">Image Deletion Warning!</h3>
      <h3 v-if="deleteCategoryCommit || deleteCategory >= 0">Category Deletion Warning!</h3>
      <div class="trash_can"></div>
      <div v-if="deleteCurrent" class="warn">
        If you delete this book, all pages, any text, images or other content included in the book will be DELETED. 
        <br/><br/>
        If you are sure, select the green button below.
      </div>
      <div v-if="deletePageModal >= 0" class="warn">
        If you delete the page, all text, images or other content included in the page will be DELETED. 
        <br/><br/>
        If you are sure, select the green button below.
        </div>
      <div v-if="imageWarn >= 0" class="warn">
        This image WILL BE DELETED!
        <br/><br/>
        If you are sure you want to delete, select the green button below.
      </div>
      <div v-if="deleteCategoryCommit || deleteCategory >= 0" class="warn functional">
        You are deleting an book Category.  If this category is in use by another user, you will be removing this Category from their books as well.
        <br/><br/>
        If you are sure you want to delete, select the green button below.
      </div>
      <div class="buttons">
        <btn class="button" v-if="deleteCurrent" v-text="'delete Book'" @click="deleteThisBook"></btn>
        <btn class="button" v-if="deletePageModal >= 0" v-text="'Delete Page'" @click.prevent="deletePage(deletePageModal); deletePageModal = -1;"></btn>
        <btn class="button" v-if="imageWarn >= 0" v-text="'delete image'" @click="editBookImg = 'url()'; tempEditBookImg = 'url()'; imageWarn = -1;"></btn>
        <btn class="button" v-if="deleteCategoryCommit" v-text="'delete category'" @click="saveCategory(3)"></btn>

        <btn class="button secondary" v-text="'cancel'" @click="deletePageModal = -1; deleteCurrent = false; imageWarn = -1; deleteCategoryCommit = false; count = 0; interval = null; deleteCategory = -1;"></btn>
      </div>
    </div>

  </main>
</template>

<script>

/* eslint-disable */
import {defineAsyncComponent, inject } from 'vue';
import HlsVideoPlayer from '../components/HlsVideoPlayer.vue'
import pdff from 'vue3-pdf';
import btn from '../components/buttons/button.vue';
import shade from '../components/shade/shade.vue';
import {VueDraggableNext} from 'vue-draggable-next';
import breadcrumb from '../components/breadcrumbtree/breadcrumb.vue';
// import VueHighlightJS from 'vue3-highlightjs';
// import '../assets/highlight-js-theme.css';
import PSPDFKitContainer from '../components/pspdfkit/PSPDFKitContainer';
import imgx from '../components/imageeditor/image.vue';
// import LoadingOverlay from '../components/preloaders/LoadingOverlay.vue';
import commentary from '../components/comment/comment.vue';

const DropZoneImage = defineAsyncComponent(
  () => import('../components/dropzones/cloudflare_image_section.vue'),
);
const DropZoneImageDiv = defineAsyncComponent(
    () => import('../components/dropzones/cloudflare_image_section_div.vue'),
);

export default {
  name: 'editView',
  inject: ['apiPath', 'imagesControl'],
  components: {
    // VueHighlightJS,
    breadcrumb,
    pdff,
    btn,
    shade,
    draggable: VueDraggableNext,
    PSPDFKitContainer,
    DropZoneImage,
    DropZoneImageDiv,
    imgx,
    // LoadingOverlay,
    commentary, 
    HlsVideoPlayer
  },
  data() {
    return {
      uploadingImage: false,
      imageWarn: -1,
      imgEdit: false,
      imageEditor: false,
      currentUrl: '',
      route: 'Page',
      commentsActive: false,
      comments: [
        {
          id: 1,
          author: 'Tim Apple',
          time: '00:00',
          text: 'Leo clearly the perfect man for the job 11111111',
          depth: 0,
          likes: 12,
          replies: [
            {
              id: 2,
              author: 'Tim Apple',
              time: '00:00',
              text: 'Leo clearly the perfect man for the job 2222222222',
              depth: 1,
              likes: 3,
              replies: [
                {
                  id: 3,
                  author: 'Tim Apple',
                  time: '00:00',
                  text: 'Leo clearly the perfect man for the job 333333333',
                  replies: [],
                  depth: 2,
                  likes: 15,
                },
                {
                  id: 4,
                  author: 'Tim Apple',
                  time: '00:00',
                  text: 'Leo clearly the perfect man for the job 44444',
                  replies: [],
                  depth: 2,
                  likes: 111,
                }
              ]
            },
          ],
        }
      ],
      // widescreen_vid: false,
      dragging: true,
      show: true,
      deleteBook: false,
      category_import:'',
      title: this.$store.getters.getBook.title,
      subtitle: this.$store.getters.getBook.subtitle,
      category: this.$store.getters.getBook.category,
      posts: {},
      categories: this.categories,
      permissions: [],
      colors: ['#252525', '#787878', '#bcbcbc', '#2884C7', '#099F45', '#F44336', '#FF6B00', '#FFA800'],
      deletePageModal: -1,
      comparisonTitle: '',
      comparisonSubTitle: '',
      titleDuplicate: '',
      subtitleDuplicate: '',
      showAllList: false,
      bookEdit: false,
      isCollapsed: false,
      tooLong: false,
      windowWidth: window.innerWidth,
      addNewCat: false,
      editBook: false,
      ifChooseColor: false,
      deleteCurrent: false,
      deleteCategory: -1,
      newCategory: { name: '', color: 'rgb(9, 159, 69)', customerid: '1', deleted: '0' },
      editCategory: false,
      editBookImg: 'url()',
      tempEditBookImg: 'url()',
      beforeLoad: false,
      count: 0,
      interval: null,
      catToDelete: '',
      newEditBookCategory: '',
      deleteCategoryCommit: false,
      visiblePDF: [],
      mobile: false,
      contents: false,
    };
  },
  computed: {
    mobile() {
      return window.innerWidth >= 600;
    },
    edit() {
      return this.$store.state.edit;
    },
    itemToScroll() {
      return this.$store.state.postToScroll;
    },
  },
  watch: {
    itemToScroll() {
      // console.log(this.$store.state.postToScroll,'this.$store.state.postToScroll')
      this.scrollAfterSearch();
    }
  },
  methods: {
    here(){
      console.log(this.posts, "this.posts");
    },
    ifMobile() {
      // console.log(this.mobile,'this.mobile')
      if (window.innerWidth < 481) {
        this.mobile = true;
        this.optionToggle = false;
      } else {
        this.mobile = false;
      }
    },
    handleLoaded(instance) {
      console.log("PSPDFKit has loaded: ", instance);
    },
    longpressCat() {
      this.increment()
      this.interval = setInterval(this.increment, 100);
      if (this.increment >= 10){
        clearInterval(this.interval)
      }
    },
    longpressCatOff() {
      clearInterval(this.interval)
      this.count = 0
    },
    increment() {
      if (this.interval != null && this.count <= 10) {
        this.count = this.count + 1;
      } else if (this.count >= 10){
        this.deleteCategory = this.catToDelete
        clearInterval(this.interval)
      }
    },
    changeCategory(cat) {
      if (this.count < 10) {
        if (cat != null){
          let newCat = { id: cat.categoryid, color:cat.color, name: cat.name }
          this.category = {categoryid: cat.categoryid, color: cat.color, name: cat.name};
          this.editBookCategory = newCat;
        } else {
          let newCat = { id: null, color: null, name: null }
          this.category = {categoryid: null, color: null, name: null};
          this.editBookCategory = newCat;
        }
        this.showAllList = false;
        this.count = 0;
        clearInterval(this.interval)
      }
    },
    deleteThisBook() {
      this.sendFetch(
        'entity/article/delete',
        {
          "articleid": this.$route.params.id,
        }
      ).then(() => {
        this.$router.go(-1)
      })
    },
    drop() {

    },
    selectedFile() {

    },
    getPres() {
      let amount = this.posts.length;
      for (let i = 0; i < amount; i++) {
        let post = this.posts[i].html;
        post = post.replaceAll('</code></pre>', '</code><span class="copy_to_clipboard"></span></pre>');
        // post = post.replaceAll(/\bhttps?:\/\/\S+/gi, '<a href="$&" target="_blank" rel="nofollow">$&</a>');
        this.posts[i].html = post;
      }
    },
    putEventListner() {
      var elements = document.getElementsByClassName("copy_to_clipboard");
      for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', console.log('CLICK'), false);
      }
    },
    
    saveCategory(n) {
      if (n == 0 && this.newCatName) {
        this.newCategory.name = this.newCatName
        this.sendFetch(
            'entity/category/add',
            {
              "name": this.newCategory.name,
              "color": this.newCategory.color,
            }
        ).then((response) => {
          if (response) {
            this.newCategory.categoryid = response.toString();
            this.categories.push(this.newCategory);
            this.category = this.newCategory;
            this.addNewCat = false;
            this.newCategory = { name: '', color: 'rgb(9, 159, 69)', project: '1', deleted: '0' };
          }
        }).catch((error) => {
          console.log('Looks like there was a problem: \n', error);
        });
      } else if (n == 1) {
        this.sendFetch(
            'entity/category/edit',
            {
              "categoryid": this.category.categoryid,
              "name": this.category.name,
              "color": this.category.color,
            }
        ).then((response) => {
          this.editCategory = false;
        }).catch((error) => {
          console.log('Looks like there was a problem: \n', error);
        });
      } else if (n == 2 && this.category.name != null && this.category.name != '') {
        this.sendFetch(
            'entity/category/delete',
            {
              "categoryid": this.deleteCategory,
            }
        ).then((response) => {
          this.category = {};
          this.sendFetch(
              'entity/category/find',
              {
              }
          ).then((result)=>{
            this.categories = result;
            this.deleteCategory = -1
          });
        }).catch((error) => {
          console.log('Looks like there was a problem: \n', error);
        });
      } else if (n == 3 && this.newEditBookCategory.categoryid !== null) {
        let deleteID;
        if (this.newEditBookCategory == ''){
          deleteID = this.category.categoryid
        } else {
          deleteID = this.newEditBookCategory.categoryid
        }
        this.sendFetch(
            'entity/category/delete',
            {
              "categoryid": deleteID,
            }
        ).then((response) => {
          this.editBookCategory = {};
          this.newEditBookCategory = {};
          this.category = {categoryid: null, color: null, name: null}
          this.sendFetch(
              'entity/category/find',
              {
              }
          ).then((result)=>{
            this.categories = result;
            this.deleteCategory = -1
            this.deleteCategoryCommit = false;
            this.count = 0;
          });
        }).catch((error) => {
          console.log('Looks like there was a problem: \n', error);
        });
      } else if (n == 4) {
        this.sendFetch(
            'entity/category/edit',
            {
              "categoryid": this.newEditBookCategory.categoryid,
              "name": this.newEditBookCategory.name,
              "color": this.newEditBookCategory.color,
            }
        ).then((response) => {
          this.editCategory = false;
          this.deleteCategory = -1;
          this.newEditBookCategory = {};
          this.count = 0;
          this.deleteCategoryCommit = false;
        }).catch((error) => {
          console.log('Looks like there was a problem: \n', error);
        });
      }
    },
    doCopy(e) {
      if (e.target.classList.contains('copy_to_clipboard')) {
        let copyText = e.target.closest('pre').innerText;
          if (document.getElementById('copied') != null) {
            // document.getElementById('copied').textContent = 'Copy Code'
            document.getElementById('copied').setAttribute('id', '')
          }
        e.target.closest('span').setAttribute('id', 'copied');
        // e.target.closest('span').textContent = 'Code Copied'
        // copyText = copyText.replace('Code Copied', '');
        // copyText = copyText.replace('Copy Code', '');
        setTimeout(function () {
          // document.getElementById('copied').textContent = 'Copy Code'
          document.getElementById('copied').setAttribute('id', '')
        }, 3000);
        navigator.clipboard.writeText(copyText);
      }
    },
    openFile(src) {
      window.open(src, '_blank') //to open in new tab
    },
    onDrop(dropResult){
      this.posts = this.applyDrag(this.posts, dropResult);
    },
    applyDrag(arr, dragResult){
      const { removedIndex, addedIndex, payload } = dragResult;

      if (removedIndex === null && addedIndex === null) return arr;
      const result = [...arr];
      let itemToAdd = payload;
      
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },
    // widescreen(event) {
    //   const element = event.target.parentNode;
    //   if (element.id === 'widescreen_vid') {
    //     element.setAttribute("id", "");
    //     this.widescreen_vid = false;
    //   } else {
    //     element.setAttribute("id", "widescreen_vid");
    //     this.widescreen_vid = true;
    //   }
    // },
    async sendFetch(url, data = null) {
      let formData = new FormData();
      formData.append('token', this.$store.state.usertoken);
      if (data !== null) {
        Object.keys(data).forEach(function(key) {
          formData.append(key, data[key]);
        });
      }
      return await fetch(`${this.apiPath}` + url, {
        method: 'POST',
        body: formData,
      }).then((res) => res.json()).then((response) => {
        if (response.result) {
          return response.result;
        } else {
          console.log('Looks like there was a problem: \n', response.error);
          if (response.error === 'Wrong token') {
            window.location.reload();
          }
          return false;
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
        return false;
      });
    },
    universalSave() {
      // console.log(this.category.categoryid, 'this.category.categoryid')
      if (this.comparisonTitle != '') {
        this.title = this.comparisonTitle;
      } else if (this.comparisonTitle == '' && this.title.toLowerCase() != 'new book') {
        this.title = this.title;
      } else {
        this.title = 'New Book';
      }
      if (this.comparisonSubTitle != '') {
        this.subtitle = this.comparisonSubTitle;
      } else if (this.comparisonSubTitle == '' && this.subtitle.toLowerCase() != 'new book description') {
        this.subtitle = this.subtitle;
      } else {
        this.subtitle = 'New Book Description'
      }
      this.sendFetch(
          'entity/article/edit',
          {
            "articleid": this.$route.params.id,
            "categoryid": this.category.categoryid,
            "name": this.title,
            "thumbnail": this.imagesControl.getLinkForDB(this.editBookImg),
            "subTitle": this.subtitle,
          }
      )
      this.widescreen_vid = false;
      this.deletePageModal = -1;
      this.deleteBook = false;
      this.editBook = false;
      this.showAllList = false;
      this.addNewCat = false;
      this.bookEdit = false;
      this.deleteCurrent = false;
      this.imageWarn = -1;
      this.count = 0;
      this.deleteCategory = -1;
      this.deleteCategoryCommit = false;
      this.comparisonTitle = '';
      this.comparisonSubTitle = '';
      this.contents = false;
      clearInterval(this.interval);
      document.getElementById('widescreen_vid')?.setAttribute("id", "");
    },
    saveSortOrder() {
      let postsIds = [];
      this.posts.forEach(function (e) {
        postsIds.push(e.id);
      });
      this.$store.state.book.data = this.posts;
      if (postsIds.length > 0) {
        this.sendFetch(
            'entity/post/resort',
            {
              "ids": JSON.stringify(postsIds)
            }
        );
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    async pasteImageFromClipboard() {
      let imageObj = await this.imagesControl.getImageFromClipboard();
      if (imageObj) {
        this.uploadingImage = true;
        this.uploadImageToCloudflare(imageObj.file).then((result) => {
          if (result) {
            //this.imagesControl.set(result, `url(${imageObj.url})`);
            this.tempEditBookImg = this.editBookImg;
            this.editBookImg = `url(${imageObj.url})`;
            this.imageEditor = true;
            this.uploadingImage = false;
          }
        });
      }
    },
    async uploadImageToCloudflare(file) {
      const formData = new FormData();
      formData.append('file', file);
      return new Promise((resolve, reject) => {
        this.getOneTimeUploadUrl().then((url) => {
          const request = new XMLHttpRequest();
          let link = url.replace('https://upload.', 'https://') + '/public';
          request.open('POST', url);
          request.onerror = () =>
              reject('oh no something went wrong!');
          request.ontimeout = () =>
              reject('oh no request timed out!');
          request.onload = () => {
            if (request.status >= 200 && request.status < 300) {
              resolve(link);
            } else {
              reject('oh no something went wrong!');
            }
          };
          request.send(formData);
        });
      });
    },
    async getOneTimeUploadUrl() {
      let response = await fetch(`${this.apiPath}cloudflareGetUploadLinkImage?token=${this.$store.state.usertoken}`);
      return await response.text();
    },
    async getData() {
      await fetch(`${this.apiPath}articleData?articleid=${this.$route.params.id}&token=${this.$store.state.usertoken}`, {
        headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
        if (response.error === undefined) {
          this.beforeLoad = true;
          this.title = response.title !== null ? response.title : '';
          this.subtitle = response.subtitle !== null ? response.subtitle : '';
          this.category = response.category;
          this.$store.state.book.category = response.category;
          this.categories = response.categories;
          this.permissions = response.permissions;
          this.articleid = response.articleid;
          this.shared = response.shared;
          this.draft = (response.draft === '1');
          this.contentDraft = response.draft;
          this.posts = response.posts;
          this.scrollAfterSearch();

          let tempImage = this.imagesControl.get(response.thumbnail.slice(4).slice(0, -1));
          this.editBookImg = tempImage !== null ? tempImage : response.thumbnail;

          this.$store.state.book.data = response.posts;
          //let resp = [response.title, `${'/article/' + response.articleid}`, '1'];
          // console.log('CONTENT FROM SERVER--->' + JSON.stringify(this.$store.state.article.data));
          this.getPres();
          this.putEventListner();
        } else {
          if (response.error === 'Wrong token') {
            window.location.reload();
          }
          if (response.error === '403') {
            window.location.href = "/403";
          }
        }
      }).catch((error) => {
        // console.log(`${this.apiPath}articleData?articleid=${this.$route.params.id}&token=${this.$store.state.usertoken}`);
        console.log('Looks like there was a problem: \n', error);
      });
    },
    uploadStarted(path) {
      this.uploading = true;
      let url = '';
      url = path;
      url = url.replace('upload.','');
      url = url + "/section";
      this.currentUrl = url;
    },
    uploadComplete(index) {
      if (index >= 0) {
        this.tempEditBookImg = this.editBookImg;
        this.editBookImg = `${'url('+this.currentUrl+')' }`;
        this.imageEditor = true;
      }
      if(index == -1) {
        console.log("Upload Error");
      }
      if(index == -2) {
        console.log("Upload Aborted");
      }
      this.uploading = false;
    },
    editedImageUploadComplete(newUrl) {
      if (newUrl !== undefined && newUrl !== null && newUrl !== '') {
        this.editBookImg = 'url(' + newUrl + ')';
        this.imageEditor = false;
      } else {
        console.log('Image is not uploaded');
      }
    },
    editedImageClose() {
      this.editBookImg = this.tempEditBookImg;
      this.imageEditor = false;
    },
    addNewPost(sortIndex = null) {
      // 2 LINES BELOW ONLY NEEDED FOR OFFLINE WORK
      this.titleDuplicate = this.title;
      this.subtitleDuplicate = this.subtitle;
      /*
      this.edit = !this.edit;
      this.posts.splice(sortIndex, 0, {
        class: "txt_box",
        comments: [],
        files: [],
        html: "",
        name: "txt",
        title: "",
        src: "",
        tags: null
      });
      this.$store.state.book.data = this.posts;
      this.$store.commit('contentEditInfo', sortIndex);
       */
      this.$store.commit('toggle');
      this.$store.state.scrollPoint = 'postIndex' + sortIndex;
      let postsIds = [];
      this.posts.forEach(function (e) {
        if (e.id !== undefined) {
          postsIds.push(e.id);
        }
        // else {
        //   postsIds.push('new');
        // }
      });
      postsIds.splice(sortIndex, 0, 'new');
      localStorage.setItem('postSortIds', JSON.stringify(postsIds));
      localStorage.setItem('currentBookId', this.$route.params.id);
      localStorage.setItem('currentBookShared', this.shared);
      localStorage.setItem('currentBookDraft', this.contentDraft);
      this.$store.state.edit = true;
      this.clearPageLocalData();
      if (this.$route.params.id === 'unpublished') {
        localStorage.setItem('createDraft', '1');
      }
      this.$router.push({ name: 'Page', params: { id: 'new'}});
    },
    clearPageLocalData() {
      localStorage.removeItem('pageData');
      localStorage.removeItem('pageInfo');
      localStorage.removeItem('pageStartState');
      delete this.$store.state.pageData;
      delete this.$store.state.pageInfo;
    },
    deletePageIndex(index) {
      this.deletePageModal = index;
      let id = this.posts[index].id.toString();
      this.sendFetch(
          'entity/post/delete',
          {
            "postid": id
          }
      );
    },
    deletePage(index) {
      this.posts.splice(index, 1);
    },
    rewriteTitle(e) {
      const element = e.target;
      this.title = element.textContent;
    },
    rewriteSubtitle(e) {
      const element = e.target;
      this.subtitle = element.textContent;
    },
    checkIfEmpty() {
      if (this.title === '') {
        this.title = ''
      }
      if (this.subtitle === '') {
        this.subtitle = ''
      }
      if (this.category === ''){
        this.category = '';
      }
    },
    applyChanges(category) {
      this.category = category;
    },
    grab(key, id) {
      const el = document.getElementById(id).firstChild.children[1].firstChild.firstChild;
      const elCode = el.innerHTML;
      this.posts[key].html = elCode;
    },
    
    gogogo(i) {
      const elem = document.getElementById(`${i}`);
      if (elem.classList.contains('collapsed')) {
        elem.classList.remove('collapsed');
        setTimeout(() => {
          this.$store.state.postToScroll = i;
          if (window.innerWidth < 1025) {
            this.contents = false;
          }
        }, 120);
      } else {
        this.$store.state.postToScroll = i;
        if (window.innerWidth < 1025) {
          this.contents = false;
        }
      }
      
    },
    collapsePost(elem, postId) {
      const el = elem.target.closest('.menu_item');
      if (el.classList.contains('collapsed')) {
        el.classList.remove('collapsed');
        this.visiblePDF.push(postId);
      } else {
        el.classList.add('collapsed');
        let index = this.visiblePDF.indexOf(postId);
        if (index !== -1) {
          this.visiblePDF.splice(index, 1);
        }
      }
    },
    scrollToElement(elementRef, timeout = null) {
      if (timeout !== null) {
        setTimeout(function(scope, elementRef) {
          const [el] = scope.$refs[elementRef];
          scope.$nextTick(() => el.scrollIntoView({ top: el.scrollHeight, behavior: "smooth" }));
          // console.log(el.scrollHeight,'el.scrollHeight')
        }, timeout, this, elementRef);
      } else {
        const [el] = this.$refs[elementRef];
        if (el) {
          this.$nextTick(() => el.scrollIntoView({top: el.scrollHeight, behavior: "smooth" }));
          // console.log(el.scrollHeight,'el.scrollHeight')
        }
      }
    },
    scrollIfNeeded(timeout = null) {
      if (this.$store.state.scrollPoint !== undefined) {
        if (this.$refs[this.$store.state.scrollPoint] === undefined) {
          return false;
        }
        this.scrollToElement(this.$store.state.scrollPoint, timeout);
        this.$store.state.scrollPoint = undefined;
        return true;
      }
      return false;
    },
    imgReloadIfNeeded() {
      let images = document.getElementsByTagName('img');
      const scope = this;
      for (let i = 0; i < images.length; i++) {
        images[i].onerror = function () {
          scope.imgOnErrorFunction(images, i);
        };
      }
    },
    imgOnErrorFunction(images, key) {
      console.log('onerror');
      const scope = this;
      setTimeout(function () {
        if (images[key].src.includes('?imgrparam=')) {
          images[key].src = images[key].src.replace('?imgrparam=','?imgrparam=1');
        } else {
          images[key].src = images[key].src + '?imgrparam=1';
        }
        images[key].onerror = function () {
          scope.imgOnErrorFunction(images, key);
        };
      }, 3000);
    },
    
    scrollAfterSearch() {
      if (this.$store.state.postToScroll != '') {
        let obj = this.posts.find(o => o.id == this.$store.state.postToScroll);
        let index = this.posts.indexOf(obj);
        this.$store.state.scrollPoint = 'postIndex' + index;
        let scrollResult = this.scrollIfNeeded(0);
        if (scrollResult) {
          this.$store.state.postToScroll = '';
          setTimeout(() => {
            this.$store.state.postToHighlight = index;
          }, 100);
        }
      }
    },
    firstIn() {
      const elementsToTest = document.querySelectorAll('.menu_item');
      let num = 100;
      if (this.mobile) {
        num = 150;
      } else { 
        num = 100;
      }
      const firstInViewport = Array.from(elementsToTest).find(element => {
        const { top, bottom } = element.getBoundingClientRect()
        return bottom > num && top < window.innerHeight
      })
      this.$store.state.postToHighlight = firstInViewport.dataset.scroll;
    },
  },
  beforeMount() {
    this.ifMobile();
    this.checkIfEmpty();
  },
  async mounted() {
    window.addEventListener('resize', () => {
      this.ifMobile();
      this.windowWidth = window.innerWidth;
    });
    await this.getData();
    this.$store.state.postToHighlight = 0;
    this.emitter.on("scroll", it => {
      this.scrollAfterSearch();
    });
    this.imgReloadIfNeeded();
    this.scrollIfNeeded(1000);
    this.titleDuplicate = (' ' + this.title).slice(1);
    this.subtitleDuplicate = (' ' + this.subtitle).slice(1);
    // this.addEl();
    // this.getPres();
    
    this.emitter.all.clear();

    this.emitter.on('go_back_book', (evt) => {
      this.$router.go(-1);
    });

    document.querySelector('.main_v8').addEventListener('scroll', () => {
      this.firstIn();
    });
  },
};
</script>

<style lang="scss" scoped>
.no_content {
  margin-bottom: 0px;
  margin-top: 16px;
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - 315px) !important;

  @media screen and (max-width: 599px) and (orientation: portrait),
  screen and (max-height: 500px) and (orientation: landscape) {
    height: calc(100vh - 260px) !important;
  }

  .content_image {
    background-image: url(../../public/img/book/page.svg);
  }
}
.menu_item:last-child{
  
}
.add_first_post{
  font-size: 1rem;
  color: #787878;
  width: 100%;
  height: 150px;
  line-height: 142px;
  border-radius: 16px;
  box-sizing: border-box;
  cursor: default;
  border: 4px dashed;
  text-align: center;
}
.add_new_post,.add_new_post_top{
  cursor: pointer;
  bottom: -34px;
  color: #787878;
  font-size: 1rem;
  padding-left: 35px;
  background-image: url(../../public/img/section/add_line.svg);
  background-repeat: no-repeat;
  background-position: center left 1px;
  width: fit-content;
  height: 40px;
  line-height: 40px;
}
.add_new_post{
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
}
.add_new_post_top{
  margin: 0 auto;
}
.toolong{
  position: absolute;
  left: 0px;
  height: 100%;
  padding: 0 3px 0 0;
  text-indent: 8px;
  background-color: #f0f0f0;
  direction: ltr;
}
.toggle_btn{
  height: auto;
  overflow: hidden;
  transition: all .3s ease-in-out;
  transition-delay: 0ms;
  max-height: 2000px;
}
.txt_toggle{
  height: auto;
  overflow-y: auto;
  transition: all .3s ease-in-out;
  transition-delay: 0ms;
  max-height: 2000px;
}
.documents{
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-content: flex-start;
  .doc{
    background-color: rgba(120, 120, 120, 0.05);
    padding: 16px 16px 16px 56px;
    border-radius: 8px;
    box-sizing: border-box;
    flex: 0 0 32.3%;
    min-width: 170px;
    margin-bottom: 8px;
    margin-right: 6px;
    max-width: 32%;
    background-image: url(../../public/img/book/doc.svg);
    background-repeat: no-repeat;
    background-position: 16px center;
    cursor: pointer;
    a{
      margin: 0px;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      height: 32px;
      line-height: 32px;
      color: #555;
      text-decoration:none;
    }
    p{
      margin: 0px;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
    }
    span{
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }
    span:first-child{
      font-size: 14px;
      line-height: 24px;
      color: #252525;
      font-weight: 500;
    }
    span:last-child{
      font-size: 12px;
      line-height: 16px;
      color: #787878;
    }
  }
  .pdf_img{
    background-image: url(../../public/img/book/pdf.svg);
  }
  .img_img{
    background-image: url(../../public/img/book/img.svg);
  }
}
.element_subtitle{
  font-size: 0.76rem;
  font-weight: 500;
  height: 24px;
  line-height: 24px;
  color: #006C3E;
}
.add_post{
  position: fixed;
  bottom: 20px;
  left: 50%;
  width: 320px;
  max-width: 90%;
  padding: 0 24px 0 35px;
  margin-left: -160px;
}
.pdf_file{
  height: 500px;
}
.edit{
  // max-width: 70%;
  // margin: 0 auto;
  .page_header{
    margin: 8px 0;
  }
  .page_subheader{
    margin-top: 0px;
    margin-bottom: 20px;
    color: #787878;
  }
}
.path{
  @media screen and (max-width: 1024px) {
    // top: 44px;
  }
}
.contents_path{
  position: fixed;
  top: 44px;
  left: 0px;
  right: 0px;
  height: 43px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  cursor: pointer;
  >div{
    color: #787878;
  }
  @media screen and (min-width: 901px) and (max-width: 1024px) {
    top: 64px;
  }
  @media screen and (min-width: 481px) and (max-width: 900px) {
    top: 52px;
  }
  @media screen and (max-width: 480px) {
    top: 44px;
  }
  span{
    font-size: 0.75rem;
    height: 22px;
    line-height: 22px;
    box-sizing: border-box;
    border: 1px solid #BCBCBC;
    color: #BCBCBC;
    text-transform: uppercase;
    position: relative;
    padding: 0 3px;
    border-radius: 4px;
    margin-right: 16px;
  }
  span::after{
    content: '';
    position: absolute;
    height: 21px;
    width: 2px;
    background-color: #099F45;
    display: inline-block;
    right: -11px;
  }
}
.contents{
  width: 375px;
  position: fixed;
  top: 64px;
  bottom: 72px;
  right: 0px;
  background-color: #fff;
  padding-left: 16px;
  box-sizing: border-box;
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    position: fixed;
    z-index: 2;
    top: 0px;
    bottom: 0px;
  }
  @media screen and (max-width: 480px) {
    width: auto;
    position: fixed;
    top: 33%;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: #fff;
    padding-left: 16px;
    box-sizing: border-box;
    border-radius: 24px 24px 0px 0px;
    z-index: 2;
  }
  .button{
    position: absolute;
    bottom: 16px;
    left: 32px;
    right: 16px;
    @media screen and (max-width: 480px) {
      right: 32px;
    }
  }
  h3{
    font-size: 1.1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 48px;
    font-weight: 500;
    text-align: center;
    color: #787878;
    position: relative;
    margin: 0px;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      margin-right: 16px;
      height: 68px;
    }
  }
  h3::before,h3::after{
    content: '';
    width: 75px;
    height: 5px;
    position: absolute;
    background-color: rgba(37, 37, 37, 0.1);
    top: 22px;
    border-radius: 3px;
    @media screen and (min-width: 771px) and (max-width: 900px) {
      width: 55px;
    }
    @media screen and (max-width: 770px) {
      width: calc(42% - 70px);
      top: 30px;
    }
  }
  h3::before{
    left: 32px;
  }
  h3::after{
    right: 32px;
  }
  .list{
    padding-left: 32px;
    position: relative;
    max-height: 100%;
    overflow-y: scroll;
    >p{
      font-size: 1rem;
      margin: 10px 0;
      color: #787878;
      position: relative;
      cursor: pointer;
      @media screen and (max-width: 480px) {
        margin: 10px 0;
        padding: 10px 0;
      }
    }
    @media screen and (max-width: 480px) {
      max-height: 445px;
    }
  }
  .list::before{
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    background-color: #f3faf6;
    left: 7px;
  }
  .list p.active::before{
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    background-color: #099f45;
    left: -25px;
    top: 0px;
  }
}
.edit_page,.delete_post{
  height: 45px;
  width: 45px;
  position: absolute;
  border: none;
  outline: none;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  right: 110px;
  top: 28px;
}
.edit_page{
  background-image: url(../../public/img/book/edit_gray.svg);
  right: 45px;
}
.delete_post{
  background-image: url(../../public/img/book/delete_gray.svg);
  right: auto;
  left: 45px;
  top: 28px;
}
.status{
  color: #fff;
  padding: 5px 16px;
  font-size: 0.76rem;
  border-radius: 8px;
  text-transform: uppercase;
  display: inline-block;
  cursor: default;
  margin-bottom: 0px;
}
.desc{
  margin-top: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #787878;
}
.draggable.txt_box,.draggable.pdf_box,.draggable.video_box{
  padding: 84px 50px 24px 50px;
  .collapse{
    top: 0px;
  }
}
.video_box,.txt_box,.pdf_box{
  padding: 32px 50px 24px 50px;
  background-color: #fff;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  box-shadow: 0px 6px 20px 2px rgba(0, 0, 0, 0.04);
  .dragger{
    cursor: grab;
    position: absolute;
    width: 150px;
    height: 50px;
    top: 25px;
    left: calc(50% - 75px);
    background-image: url(../../public/img/book/dragger.svg);
    background-repeat: no-repeat;
    background-position: center 20px;
    background-size: 75px;
  }
  .collapse_wrapper_full{
    position: absolute;
    width: calc(100% - 150px);
    height: 63px;
    right: 45px; 
    top: 0px;
  }
  .collapse_wrapper_small{
    position: absolute;
    width: 45px;
    height: 45px;
    right: 45px; 
    top: 73px;
  }
  .collapse{
    position: absolute;
    right: 0px;
    top: 22.5px;
    display: inline-block;
    height: 45px;
    width: 45px;
    background-image: url(../../src/assets/arrow.svg);
    // transform: rotate(180deg);
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: center center;
    transition: all .2s;
    svg{
      fill: #252525;
    }
  }
  h3{
    margin-top: 0px;
    margin-bottom: 4px;
  }
  video{
    border-radius: 16px;
    height: auto;
    aspect-ratio: 16/9;
    width: 100%;
    margin-bottom: 24px;
  }
  .media{
    border-top: 1px solid rgba(37, 37, 37, 0.1);
    padding-top: 16px;
    text-align: left;
    display: flex;
    align-content: center;
    .hide_comments{
      position: absolute;
      color: #787878;
      right: 0px;
      top: -40px;
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background-image: url(../../public/img/close.svg);
      background-repeat: no-repeat;
      cursor: pointer;
      background-position: center left;
    }
    span{
      color: #787878;
      background-repeat: no-repeat;
      background-position: center left;
      height: 35px;
      line-height: 35px;
      padding-left: 30px;
      font-size: 1rem;
      margin-right: 30px;
      cursor: pointer;
      display: inline-block;
      background-size: 25px;
    }
    .share,.comment_section{
      height: 35px;
      line-height: 35px;
      display: block;
      float: right;
      color: #787878;
      font-size: 1rem;
      background-repeat: no-repeat;
      background-image: url(../../public/img/book/share.svg);
      background-position: center right;
      background-size: 25px;
      cursor: pointer;
    }
    .comment_section{
      margin-left: auto;
      background-image: url(../../public/img/book/comment.svg);
    }
    .views{
      background-image: url(../../public/img/book/view.svg);
    }
    .likes{
      background-image: url(../../public/img/book/thumbs-up.svg);
    }
    .comments{
      background-image: url(../../public/img/book/comment.svg);
    }
  }
}
.draggable.txt_box,.draggable.pdf_box,.draggable.video_box{
  margin-bottom: 40px;
}
#widescreen_vid{
  position: fixed;
  z-index: 3;
  width: 90%;
  box-sizing: border-box;
  left: 5%;
  top: 5%;
  height: 90%;
  max-height: 90%;
}
.main_v8{
  transition: all .2s ease-in-out;
  padding: 50px 50px 600px;
  @media screen and (min-width: 901px) and (max-width: 1024px) {
    padding: 46px 40px 700px;
  }
  @media screen and (min-width: 771px) and (max-width: 900px) {
    padding: 46px 32px 700px;
  }
  @media screen and (min-width: 481px) and (max-width: 770px) {
    right: 0px;
    padding: 46px 32px 600px;
  }
  @media screen and (max-width: 480px) {
    padding: 50px 16px 400px;
  }
}
.video_box{
  .toggle_btn{
    height: inherit;
    max-height: calc(100% - 60px);
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
}
.video_box.collapsed,.txt_box.collapsed,.pdf_box.collapsed{
  .toggle_btn{
    max-height: 0;
    transition: all .3s ease-in-out;
  }
  .collapse{
    transform: rotate(180deg);
  }
}
.text_to_style{
  p{
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px)
  and (min-height: 961px) and (orientation: portrait) {
    .edit{
      max-width: none;
    }
    .main_v8{
      top: 64px !important;
      
      @media screen and (min-width: 481px) and (max-width: 900px){
        top: 52px !important;
      }
    }
    #widescreen_vid{
      height: auto;
    }
  }
  @media screen and (max-width: 599px) and (orientation: portrait){
    .active_info{
      bottom: 0px !important;
    }
    .page_subheader{
      margin: 0px 0px 8px 0px;
      line-height: 24px;
      color: #787878;
    }
    .add_new_post,.add_new_post_top{
      bottom: -34px;
      color: #787878;
      font-size: 1rem;
      padding-left: 35px;
      background-image: url(../../public/img/section/add_line.svg);
      background-repeat: no-repeat;
      background-position: center left;
      left: calc(50% - 49px);
    }
    .add_new_post{
      position: absolute;
      bottom: -34px;
      left: 50%;
      transform: translateX(-50%);
      line-height: 24px;
      height: 24px;
      width: fit-content;
    }
    .add_new_post_top{
      line-height: 44px;
      height: 44px;
      margin: 0 auto;
      width: fit-content;
    }
}
@media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 500px) and (orientation: landscape){
.video_box,.txt_box,.pdf_box{
  .dragger{
    top: 0px;
  }
}
  .edit_button{
    top: 70px;
  }
  .main_v8{
    // top: 44px;
    .page_header{
      margin-bottom: 0px;
    }
  }
  .edit{
    max-width: none;
  }
  .draggable{
    margin-bottom: 44px;
    .edit_page,.delete_post{
      top: 10px;
    }
  }
  .video_box .collapse, .txt_box .collapse, .pdf_box .collapse{
    top: 16px;
  }
  .draggable.txt_box,.draggable.pdf_box,.draggable.video_box{
    .collapse{
      top: 0px;
    }
  }
  .documents{
    .document{
      flex: 0 0 100%;
      max-width: 100%;
    }
    .pdf_img{
      background-image: url(../../public/img/book/pdf.svg);
    }
  }
  .delete_post{
    left: 12px;
    right: auto;
  }
  .edit_page {
    right: 12px;
  }
  .edit_page,.delete_post{
    width: 30px;
    height: 30px;
  }
  .draggable.txt_box,.draggable.pdf_box,.draggable.video_box{
    padding: 45px 20px 15px 20px;
  }
  
  .collapsed{
    .widescreen{
      display: none;
    }
  }
  #widescreen_vid{
    height: auto;
    .collapse{
      display: none;
    }
    .widescreen{
      right: 16px;
      top: 13px;
    }
  }
  .video_box{
    .toggle_btn{
      border-radius: 16px;
    }
  }
  .video_box,.txt_box,.pdf_box{
    padding: 16px;
    border-radius: 16px;
    
    .collapse_wrapper_full{
      width: calc(100% - 50px);
      right: 12px;
      height: 45px;
      top: 0px;
    }
    .collapse_wrapper_small{
      position: absolute;
      right: 12px;
      width: 30px;
      height: 30px;
      top: 50px;
    }
    .collapse{
      right: 0px;
      width: 30px;
      height: 30px;
      background-position: 3px center;
      top: 12px
    }
    .widescreen{
      right: 47px;
      top: 10px;
    }
    .element_subtitle{
      font-weight: 500;
      height: 16px;
      line-height: 16px;
      margin-top: 8px;
      display: inline-block;
      width: calc(100% - 30px);
    }
    h3{
      margin-top: 8px;
      font-weight: 500;
      max-width: calc(100% - 100px);
    }
    .media{
      span{
        padding-left: 30px;
        margin-right: 20px;
      }
      .share,.comment_section{
        width: 35px;
        height: 35px;
        span{
          display: none;
        }
      }
      .share{
        margin-left: 10px;
      }
    }
  }
}
@media screen and (max-height: 500px) and (orientation: landscape){
}
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  box-sizing: border-box;
}

.category_list-enter-active,
.category_list-leave-active{
  transition: all 0.3s ease-in-out;
}
.category_list-enter-from,
.category_list-leave-to{
  max-height: 0px;
}


.contents-enter-active,
.contents-leave-active{
  transition: all .2s ease-in-out;
}
.contents-enter-from,
.contents-leave-to{
  right: -375px;
}
.contents_mobile-enter-active,
.contents_mobile-leave-active{
  transition: all .2s ease-in-out;
}
.contents_mobile-enter-from,
.contents_mobile-leave-to{
  top: 100%;
}
</style>
