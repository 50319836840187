<template>
  <div class="comment" >
    <div v-for="comment in comments" :key="comment.id">
      <div class="info">
        <div class="avatar" :style="(comment.userMetadata?.image !== undefined ? 'background-image: url('+ comment.userMetadata?.image +');' : '')"></div>
        <div class="author">
          <h4>{{ comment.userMetadata?.name + ' ' + comment.userMetadata?.surname }}</h4>
          <span>{{ comment.created_at }}</span>
        </div>
        <div v-if="getLikes(comment.users_likes).includes(store.state.user.userId)" class="likes likes-active">{{ (getLikes(comment.users_likes).length > 0 ? getLikes(comment.users_likes).length : '') }}</div>
        <div v-else class="likes">{{ (getLikes(comment.users_likes).length > 0 ? getLikes(comment.users_likes).length : '') }}</div>
        <div class="reply">Reply</div>
      </div>
      
      <p class="text">{{ comment.content }}</p>

      <div v-if="replies" v-for="reply in comment.children" :key="reply.id" :style="{ paddingLeft: reply.depth * 15 + 'px'}">
        <div class="hide_replies">
          <div @click="replies = !replies">Hide replies</div>
        </div>
        
        <div class="info">
          <div class="avatar" :style="(reply.userMetadata?.image !== undefined ? 'background-image: url('+ reply.userMetadata?.image +');' : '')"></div>
          <div class="author">
            <h4>{{ reply.userMetadata?.name + ' ' + reply.userMetadata?.surname }}</h4>
            <span>{{ reply.created_at }}</span>
          </div>
          <div v-if="getLikes(reply.users_likes).includes(store.state.user.userId)" class="likes likes-active">{{ (getLikes(reply.users_likes).length > 0 ? getLikes(reply.users_likes).length : '') }}</div>
          <div v-else class="likes">{{ (getLikes(reply.users_likes).length > 0 ? getLikes(reply.users_likes).length : '') }}</div>
          <div class="reply">Reply</div>
        </div>
        <p class="text">{{ reply.content }}</p>

        <div v-for="answer in reply.children" :key="answer.id" :style="{ paddingLeft: answer.depth*15 + 'px'}">
          <div class="info">
            <div class="avatar" :style="(answer.userMetadata?.image !== undefined ? 'background-image: url('+ answer.userMetadata?.image +');' : '')"></div>
            <div class="author">
              <h4>{{ answer.userMetadata?.name + ' ' + answer.userMetadata?.surname }}</h4>
              <span>{{ answer.created_at }}</span>
            </div>
            <div v-if="getLikes(answer.users_likes).includes(store.state.user.userId)" class="likes likes-active">{{ (getLikes(answer.users_likes).length > 0 ? getLikes(answer.users_likes).length : '') }}</div>
            <div v-else class="likes">{{ (getLikes(answer.users_likes).length > 0 ? getLikes(answer.users_likes).length : '') }}</div>
          </div>
          <p class="text">{{ answer.content }}</p>
        </div>
      </div>
    </div>
    
    <div v-if="!replies" class="show_replies">
      <div @click="replies = !replies">Show replies</div>
    </div>

    <label class="add_comment">
      <span class="open" @click="commentEdit = true"></span>
      <span class="sign" @click="assignActive = true"></span>
      <span class="files"></span>
      <p class="textarea" type="text" contenteditable @input="here($event)">{{ 123 }}</p>
      <div class="button_set" v-if="commentBody !== ''">
        <div class="button secondary" @click.stop="commentBody = ''">Cancel</div>
        <div class="button" @click.stop="addComment()">Send</div>
      </div>
    </label>
    <div class="attached_people" v-if="listOfAttached.length !== 0">
      <div v-for="name in listOfAttached" :key="name">{{ name }} <span @click="removeName(name)"></span></div>
    </div>
    
    <div class="shadow" :class="assignActive ? 'shadow_assign' : null" v-if="commentEdit || assignActive" @click="commentEdit = false; assignActive = false"></div>
    <assign class="assign" v-if="assignActive" @cancel="cancelAssign" @nameTransition="fillNames" :listOfAttached="listOfAttached"/>
    <div class="comment_edit" v-if="commentEdit">
      <editor v-model="commentBody"
        api-key="no-api-key"
        :init="{
          menubar: false,
          plugins: 'lists link image imagetools nonbreaking paste',
          toolbar: 'undo redo | image | alignleft aligncenter alignright | bullist numlist | bold underline italic | h1 h2 h3',
          object_resizing: 'img',
          branding: false,
          file_picker_types: 'file image media',
          content_style: `@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800'); @import url('/mce_editor.css'); body { font-family: Inter; }; ol,ul { padding-left: 20px;}`,
          image_uploadtab: true,
          paste_data_images: true,
          image_advtab: true,
          imagetools_cors_hosts: ['localhost:8080'],
          imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
          content_css: '/content.css',
          importcss_append: true,
          paste_preprocess: function (pl, o) {
            o.content = smart_paste(o.content); //todo fix and use again
          },
          images_upload_handler: tinymceImageUploadHandler,
        }"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, toRefs, defineEmits } from 'vue';
import Editor from '@tinymce/tinymce-vue';
import assign from './assign.vue';
import {useStore} from "vuex";
import {SuperTokensController as STC} from "@/services/SuperTokensController";

const props = defineProps({ comments: Array, postId: String, entityType: String });
const store = useStore();
let comments = ref(props.comments);
const postId = props.postId;
const entityType = props.entityType;
let replies = ref(false);
let assignActive = ref(false);
let commentEdit = ref(false);
let commentBody = ref('');
let listOfAttached = ref([]);
let commentBase = ref({
  id: 4,
  author: 'Tim Apple',
  time: '00:00',
  content: '',
  children: [],
  depth: 2,
  users_likes: null,
});


function cancelAssign() {
  assignActive.value = false;
}
function here(e) {
  commentBody.value = e.target.innerText;
  // console.log(e.target.innerText, 'commentBody');
}
function getLikes(likes) {
  let likesArray = JSON.parse(likes);
  return (Array.isArray(likesArray) ? likesArray : []);
}
function removeName(name) {
  let i = listOfAttached.value.indexOf(name);
  if (i !== -1) {
    listOfAttached.value.splice(i, 1);
  }
}
function fillNames(arr) {
  console.log(arr, 'arr')
  listOfAttached.value = arr;
  assignActive.value = false;
}

function tinymceImageUploadHandler(blobInfo, success, failure, progress) {
  const reader = new FileReader();
  reader.onload = function() {
    const base64String = reader.result;
    success(base64String);
  };
  reader.readAsDataURL(blobInfo.blob());
}
function addComment() {
  commentBase.value.content = commentBody.value;
  comments.value.push({ ...commentBase.value });
  commentBody.value = '';
  STC.fetchServerPost('entity/add/comment', {
    'entity_type': entityType,
    'entity_id': postId,
    'content': commentBody.value,
  });
  //postId
}
function smart_paste(str) {
  str = str.replaceAll(/(?:class|className)=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/g, ``);
  str = str.replaceAll(/(?:id|className)=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/g, ``);
  if (str.includes('https://www.youtube.com/') || str.includes('https://youtube.com/') || str.includes('https://youtu.be/')) {
    str = str.replaceAll(/(?<!["'`])((?:http|https|ftp):\/\/\S+)(?!["'`])/gm, `<a href='$&' target='_blank' rel='nofollow'>$&</a>`);
  }
  str = str.replaceAll(/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/gm, ``);
  str = str.replaceAll(/(?:<script[^>]*)(?:(?:\/>)|(?:>.*?<\/script>))/gm, ``);
  str = str.replaceAll(/(?:<style[^>]*)(?:(?:\/>)|(?:>.*?<\/style>))/gm, ``);
  str = str.replaceAll(/\bwidth="(\d+)"/g, ``);
  str = str.replaceAll(/\bheight="(\d+)"/g, ``);
  str = str.replaceAll(/position:(fixed)/gm, ``);
  str = str.replaceAll(/position:( fixed)/gm, ``);
  return str;
}
</script>

<style lang="scss" scoped>
.attached_people{
  display: flex;
  flex-wrap: wrap;
  >div{
		height: 32px;
		line-height: 32px;
    border: 1px solid #006C3E;
		box-sizing: border-box;
		width: fit-content;
		padding: 0 0 0 5px;
		border-radius: 3px;
		color: #006C3E;
		background-color: rgba(0, 108, 62, 0.15);
    margin: 5px 5px 5px 0;
    display: flex;
    justify-content: center;
    span{
      display: inline-block;
      cursor: pointer;
      width: 24px;
      height: 30px;
      text-align: center;
      color: #787878;
      background-image: url(../../../public/img/close.svg);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
.comment{
  width: 100%;
}
.shadow{
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.shadow_assign{
  background-color: transparent;
}
.comment_edit{
  position: fixed;
  top: 100px;
  bottom: 100px;
  left: 100px;
  right: 100px;
  z-index: 2;
}
.info{
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  .avatar{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-image: url(../../../public/img/empty_user_image.svg);
    background-size: contain;
    margin-right: 16px;
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      margin-right: 8px;
    }
  }
  h4{
    font-weight: 500;
  }
  span{
    color: #bcbcbc
  }
  h4,span{
    max-height: 20px;
    line-height: 20px;
    margin: 0;
  }
  .likes,.reply{
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    color: #787878;
    font-size: 1rem;
  }
  .likes{
    margin-left: auto;
    padding-left: 40px;
    background-image: url(../../../public/img/book/thumbs-up.svg);
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: center left 5px;
  }
  .likes-active{
    background-image: url(../../../public/img/book/thumbs-up-active.svg);
  }
  .reply{
    margin-left: 32px;
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      margin-left: 12px;
    }
  }
}
.text{
  margin-left: 56px;
  margin-top: 0;
  margin-bottom: 20px;
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    margin-bottom: 8px;
    margin-left: 48px;
  }
}
.hide_replies{
  height: 40px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid rgba(37, 37, 37, 0.1);
  margin-bottom: 10px;
  div{
    position: absolute;
    right: 0;
    color: #787878;
    line-height: 40px;
    padding-left: 40px;
    background-image: url(../../../public/img/book/hide_replies.svg);
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: center left;
  }
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    margin-top: -8px;
  }
}
.show_replies{
  display: flex;
  justify-content: center;
  color: #787878;
  line-height: 40px;
  height: 40px;
  div{
    cursor: pointer;
  }
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    margin-top: -8px;
  }
}
.add_comment{
  display: inline-block;
  position: relative;
  margin-top: 20px;
  width: 100%;
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    margin-top: 0;
  }
  .textarea{
    padding: calc((48px - 1.2rem)/2) 100px calc((48px - 1.2rem)/2) 60px;
    border: 1px solid #bcbcbc;
    width: 100%;
    outline: none;
    border-radius: 5px;
    font-size: 1rem;
    line-height: 1.2rem;
    box-sizing: border-box;
    color: #252525;
    resize: none;
    overflow: hidden;
    cursor: text;
    margin: 0;
  }
  .open,.sign,.files{
    height: 40px;
    width: 40px;
    position: absolute;
    top: 4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35px;
    cursor: pointer
  }
  .open{
    background-image: url(../../../public/img/book/open_in_new_window.svg);
    left: 16px
  }
  .sign{
    background-image: url(../../../public/img/book/sign.svg);
    right: 58px
  }
  .files{
    background-image: url(../../../public/img/book/files.svg);
    right: 16px
  }
  .button_set{
    /*
    position: absolute;
    right: 0px;
    bottom: -100px;
     */
    padding-top: 5px;
    width: 100%;
    display: inline-block;
    text-align: right;
    .button{
      width: 150px;
    }
    .button:first-child{
      margin-right: 10px;
    }
  }
}
</style>